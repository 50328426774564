<template>
  <div class="form">
    <div class="top-box">
      <h3>{{ $t("demand.requirement_reporting_record") }}</h3>
    </div>
    <a-form :model="formData" layout="vertical" ref="formRef">
      <secret
        :value="formData.secretData"
        :disabled="disabled"
        @secret:change="(e) => (formData.secretData = e)"
      />
      <a-row :gutter="24">
        <a-col :span="12">
          <!-- 需求名称 -->
          <a-form-item
            :label="$t('demand.form.training_name')"
            name="trainName"
            :rules="{
              required: true,
              message: $t('demand.form.training_name_p'),
              trigger: 'change',
            }"
          >
            <a-input
              v-model:value="formData.trainName"
              :maxlength="50"
              :placeholder="$t('demand.please_enter_name')"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <!-- 预算费用 -->
          <a-form-item
            :label="$t('demand.form.training_budget')"
            name="budget"
            :rules="{
              required: true,
              type: 'number',
              message: $t('demand.form.training_budget_p'),
              trigger: 'change',
            }"
          >
            <a-input-number
              style="width: calc(100% - 30px)"
              :min="0"
              :max="99999"
              :precision="2"
              v-model:value="formData.budget"
              :placeholder="$t('demand.please_enter_budget_cost')"
            />
            <span style="padding-left: 10px">{{ $t("demand.yuan") }}</span>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <!-- 举办时间 -->
          <a-form-item
            :label="$t('demand.form.holding_time')"
            name="taskTime"
            :rules="{
              required: true,
              type: 'array',
              message: $t('demand.form.holding_time_p'),
              trigger: 'change',
            }"
          >
            <a-range-picker
              allowClear
              v-model:value="formData.taskTime"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              style="width: 100%"
            >
              <template #suffixIcon>
                <CalendarOutlined />
              </template>
            </a-range-picker>
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <!-- 培训需求内容 -->
          <a-form-item
            :label="$t('demand.form.content_of_training_needs')"
            name="content"
            :rules="{
              required: true,
              message: $t('demand.form.content_of_training_needs_p'),
              trigger: 'change',
            }"
          >
            <a-textarea
              v-model:value="formData.content"
              :rows="5"
              :maxlength="500"
              :placeholder="$t('demand.please_enter_requirements')"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <!-- 培训需求理由 -->
          <a-form-item
            :label="$t('demand.form.reason_for_training_need')"
            name="reason"
          >
            <a-textarea
              v-model:value="formData.reason"
              :rows="5"
              :maxlength="500"
              :placeholder="$t('demand.please_enter_reason')"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <div class="btns">
        <a-button type="primary" :loading="loading" @click="save">
          {{ $t("CM_Save") }}
          <!-- 保存 -->
        </a-button>
        <a-button @click="back">
          {{ $t("CM_Cancel") }}
          <!-- 取消 -->
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, ref, createVNode, getCurrentInstance } from "vue";
import { demandSave } from "@/api/demand";
import { dateFormat } from "@/utils/tools";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import secret from "@/components/secret.vue";
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    secret,
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      loading: false,
      formData: {
        taskId: props.dataSource.taskId,
        demandId: 0,
        trainName: "",
        taskTime: [],
        budget: "",
        content: "",
        reason: "",
        secretData: 0,
      },
    });

    if (props.editData) {
      let d = JSON.parse(JSON.stringify(props.editData));
      stateData.formData = d;
      stateData.formData.taskTime = [
        dateFormat(d.startTime),
        dateFormat(d.endTime),
      ];
    }

    const formRef = ref(null);
    const save = () => {
      formRef.value.validate().then(() => {
        stateData.loading = true;
        let params = JSON.parse(JSON.stringify(stateData.formData));
        params.taskTime[0] = params.taskTime[0] + " 00:00";
        params.taskTime[1] = params.taskTime[1] + " 23:59";
        demandSave(params).then((res) => {
          stateData.loading = false;
          if (res.ret == 0) {
            if (props.editData) {
              proxy.$message.success($t("CM_Success"));
              back();
            } else {
              Modal.confirm({
                title: $t("demand.add_save_tip"),
                // 提交成功，是否继续提交需求？
                icon: () => createVNode(ExclamationCircleOutlined),
                onOk() {
                  formRef.value.resetFields();
                },
                onCancel() {
                  formRef.value.resetFields();
                  back();
                },
              });
            }
          } else {
            proxy.$message.error(res.msg || $t("CM_SubmitFail"));
          }
        });
      });
    };

    const back = () => {
      emit("back");
    };

    return {
      moment,
      ...toRefs(stateData),
      formRef,
      save,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.form {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  margin-top: 20px;
  .top-box {
    padding-bottom: 32px;
    h3 {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin: 0;
      word-break: break-all;
    }
  }
}
.btns {
  text-align: center;
  .ant-btn {
    &:last-child {
      margin-left: 12px;
    }
  }
}
</style>
